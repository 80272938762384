import { Dispatch } from "redux";
import { getVendorOrderExtDetails, getVendorOrdersDetails, postVendorOrderApproval } from "../../api/APIUtils";
import { GetStoredToken, TokenDecoded, IsTokenValid, RemoveStoredToken } from "../../api/TokenStorage";

import { IApprovedOrdersDto, IAuthToken, IVendorOrderDetailsExt, IVendorOrdersDto, JwtPayloadExt, TVendorOrderStatus } from "../../common/types";
import { Action, setUserAction, setAuthSuccessAction, setUserAuthStateAction, clearAuthAction, signOutUserAction, loadOrdersSuccessAction, loadOrdersStartAction, loadOrdersFailAction, setAuthStartAction, setAuthFailAction, loadOrderDetailsStartAction, loadOrderDetailsSuccessAction, loadOrderDetailsFailAction, approveOrdersStartAction, approveOrdersSuccessAction, approveOrdersFailAction } from "../actions";

export const initAuthAndUser = () => (dispatch: Dispatch<Action>) => {
  dispatch(setAuthStartAction());
  try {
    const accessToken: string = GetStoredToken();
    const jwtPayload: JwtPayloadExt | null = TokenDecoded(accessToken);

    if (!jwtPayload) return;
    dispatch(setUserAction(jwtPayload));
    dispatch(setAuthSuccessAction(jwtPayload, accessToken));
  } catch(err) {
    let message = 'Unknown error'
    if (err instanceof Error) message = err.message;
    dispatch(setAuthFailAction(message));
  }
}

export const setUserIsAuthed = (authToken: IAuthToken) => (dispatch: Dispatch<Action>) => {
  const tokenIsValid = IsTokenValid(authToken);
  dispatch(setUserAuthStateAction(tokenIsValid));
}

export const signOutUser = () => (dispatch: Dispatch<Action>) => {
  dispatch(clearAuthAction());
  dispatch(signOutUserAction());
  RemoveStoredToken();
}

export const loadOrders = (orderStatus: TVendorOrderStatus) => async (dispatch: Dispatch<Action>) => {
  // let skip = page * ordersPerPage;
  // let top = ordersPerPage;
  try {
    dispatch(loadOrdersStartAction());
    const res = await getVendorOrdersDetails({ orderStatus });
    const orderData = res as IVendorOrdersDto ;
    dispatch(loadOrdersSuccessAction(orderData));
  } catch(err) { 
    let message = 'Unknown Error'
    if (err instanceof Error) message = err.message;
    dispatch(loadOrdersFailAction(message));
  }
}

export const loadOrderDetails = (orderId: string) => async (dispatch: Dispatch<Action>) => {
  try {
    dispatch(loadOrderDetailsStartAction());
    const res = await getVendorOrderExtDetails({ orderId });
    const orderData = res as IVendorOrderDetailsExt;
    dispatch(loadOrderDetailsSuccessAction(orderData));
  } catch(err) { 
    let message = 'Unknown Error'
    if (err instanceof Error) message = err.message;
    dispatch(loadOrderDetailsFailAction(message));
  }
}

export const approveOrder = (orderId: string) => async (dispatch: Dispatch<Action>) => {
  try {
    dispatch(approveOrdersStartAction([orderId]));
    const res = await postVendorOrderApproval({ orderIds: [orderId] });
    if (!Array.isArray(res)) throw new Error(res.message);
    const ordersApproved = res as IApprovedOrdersDto[];

    const orderIds = ordersApproved.map(order => order.orderId)
    dispatch(approveOrdersSuccessAction(orderIds));
  } catch(err) {
    let message = 'Unknown Error'
    if (err instanceof Error) message = err.message;
    dispatch(approveOrdersFailAction([orderId], message));  
  }
}