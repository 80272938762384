import "./styles/index.css";
import App from "./App";

import { createRoot, Root } from 'react-dom/client'
import { BrowserRouter as Router } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

import { themeProvider } from './styles/theme'
import { Provider } from "react-redux";
import { store } from './store/index'

const rootElement: Element = document.getElementById('root') as Element;
const root: Root = createRoot(rootElement);
root.render(
  <Provider store={store}>
    <Router>
      <ChakraProvider resetCSS theme={themeProvider}>
        <App />
      </ChakraProvider>
    </Router>
   </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
