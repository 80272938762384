import {
  Box,
  Flex,
  HStack,
  Text,
  VStack
} from "@chakra-ui/react";
import { connect, ConnectedProps } from "react-redux";
import { useEffect, useRef } from "react";
import { AnyAction } from 'redux';
import { ThunkDispatch } from "redux-thunk";
import { InfoIcon } from "@chakra-ui/icons";

import { RootState } from "../store";
import { IVendorOrderDetails, TVendorOrder, TVendorOrderStatus } from "../common/types";
import { loadOrders } from "../store/action-creators";
import { TOrderFilter } from "../store/reducers/ordersReducer";
import { ORDER_STATUS_LABELS } from "./Orders";
import Loading from "./Loading";
import OrderCard from "./OrderCard";
import PaginationMenu from "./PaginationMenu";
import { setActiveOrderFilterAction, setActivePageAction } from "../store/actions";

const OrdersList = (props: Props): JSX.Element => {
  const { orderFilter, page, totalNumOrders, allOrders, loading, loaded, error, setOrderFilter, setPage, loadOrders } = props;

  const ordersPerPage = 10;
  const orders: TVendorOrder[] = Object.values(allOrders).slice(page * ordersPerPage, (page + 1) * ordersPerPage);
  const listRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const orderStatus = ORDER_STATUS_LABELS[orderFilter].status[0];
    loadOrders(orderStatus);
  }, [orderFilter, setPage, loadOrders])

  const selectOrderFilter = (filter: TOrderFilter) => {
    setPage(0);
    setOrderFilter(filter);
  }

  const scrollToTop = () => {
    listRef.current?.scrollTo({ 
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });
  }

  const displayOrders = () => {
    if (loading || error) {
      return <Loading error={error} />
    } else if (orders.length > 0) {
      return orders.map((order, i) => 
        <OrderCard
          key={i}
          order={order as IVendorOrderDetails}
          orderColor={ORDER_STATUS_LABELS[orderFilter].colorScheme}
        />
      );
    } else {
      return (
        <Flex 
          w="100%"
          h="100%"
          minH="inherit"
          alignItems="center"
          justifyContent="center"
        >
          <VStack>
            <InfoIcon 
              w={30}
              h={30}
              color="gray.300"
            />
            <Text>No Orders found.</Text>
          </VStack>
        </Flex>
      )
    }
  }

  return (
    <Flex
      w="100%"
      h="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Box 
        w="100%"  
        className="container"
        >
          <HStack 
            textColor="black" 
            background="gray.600" 
            paddingY="11px" 
            paddingX="24px" 
            spacing="20px" 
            position="sticky"
            top="0"
            borderRadius="3px 3px 0 0"
            zIndex="1"
          >

            {Object.entries(ORDER_STATUS_LABELS).map(([filter, item]) => {
              const { label, colorScheme } = item;
              return (
                <Box
                  key={filter}
                  onClick={() => selectOrderFilter(filter as TOrderFilter)}
                  fontSize="14px"
                  fontWeight="500"
                  padding="5px 15px"
                  bg={orderFilter === filter ? colorScheme: ""}
                  rounded="3px"
                  textColor="white"
                  transition="200ms"
                  _hover={{ 
                    textColor: "white",
                    background: colorScheme, 
                    cursor: "pointer", 
                  }}>
                  { label }
                </Box>
              )}
            )}
          </HStack>
          <Box 
            ref={listRef}
            overflow="auto" 
            minH="370px"
            h="calc(100vh - 140px - 58px - 85px)"> 
            {/* Screen - Header - Status Bar - Bottom */}
              {displayOrders()}
          </Box>
      </Box>
      <PaginationMenu 
        ordersLoaded={loaded}
        activePage={page}
        prevPage={() => setPage(page - 1)}
        nextPage={() => setPage(page + 1)}
        ordersPerPage={ordersPerPage}
        totalNumOrders={totalNumOrders}
        scrollToTop={scrollToTop}
      />
    </Flex>
  );
}

type PropsFromRedux = ConnectedProps<typeof connector>
interface Props extends PropsFromRedux {}

const mapStateToProps = (state: RootState) => ({ 
  orderFilter: state.orders.activeState.orderFilter, 
  page: state.orders.activeState.page,
  allOrders: state.orders.entities,
  totalNumOrders: state.orders.results,
  loading: state.orders.loading,
  loaded: state.orders.loaded,
  error: state.orders.error,
});
const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>) => ({
  loadOrders: (orderStatus: TVendorOrderStatus) => dispatch(loadOrders(orderStatus)),
  setOrderFilter: (orderFilter: TOrderFilter) => dispatch(setActiveOrderFilterAction(orderFilter)),
  setPage: (page: number) => dispatch(setActivePageAction(page))
});
const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(OrdersList);
