import Cookie from "universal-cookie";
import jwt_decode from "jwt-decode";

import { IAuthToken, JwtPayloadExt } from "../common/types";

export const GetStoredToken = (): string => {
  const cookies = new Cookie();
  const cookieKey = "token_" + process.env.REACT_APP_CLIENT_NAME;
  let storedToken = cookies.get(cookieKey);
  return storedToken;
}

export const RemoveStoredToken = () => {
  const cookies = new Cookie();
  const cookieKey = "token_" + process.env.REACT_APP_CLIENT_NAME;
  cookies.remove(cookieKey);
}

export const StoreToken = (accessToken: string) => {
  const cookies = new Cookie();
  const cookieKey = "token_" + process.env.REACT_APP_CLIENT_NAME;
  cookies.set(cookieKey, accessToken, {
    path: "/",
  });
}

export const TokenDecoded = (tokenCode: string): JwtPayloadExt | null => {
  try {
    const tokenPayload: JwtPayloadExt = jwt_decode(tokenCode); 
    return tokenPayload;
  } catch(err) {
    let message = 'Error decoding JWT '
    if (err instanceof Error) message += err.message;
    throw new Error(message);
  } 
}

export const IsTokenValid = (authToken: IAuthToken): boolean => {
  if (!authToken?.expirationDate) return false;
  try {
    const { expirationDate } = authToken;
    if (expirationDate) {
      const timeToExpire = (+expirationDate - +new Date());
      if (timeToExpire < 10000) {
        RemoveStoredToken();
        return false;
      }
    }
  } catch (err) {
    console.log('Error validating access token:', err)
  }
  return true;
};