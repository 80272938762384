import { IAuthToken, ILoadingState, JwtPayloadExt } from '../../common/types';
import { ActionType } from '../action-types';
import { Action } from "../actions/index"

export interface IAuthState extends IAuthToken, ILoadingState {};

const initialState = {
  loading: false,
  loaded: false,
  error: "",
} as IAuthState

export const reducer = (state: IAuthState = initialState, action: Action): IAuthState => {
  switch (action.type) {
    case ActionType.SET_AUTH_START:
      return {
        ...state,
        loading: true,
        loaded: false,
      }
    case ActionType.SET_AUTH_SUCCESS:
      const { jwt, accessToken }: { jwt: JwtPayloadExt, accessToken: string } = action.payload;
      return {
        ...state,
        accessToken,
        expirationDate: new Date(jwt.exp * 1000),
        scope: jwt.scope,
        iss: jwt.iss || "",
        loading: false,
        loaded: true,
      }
    case ActionType.SET_AUTH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case ActionType.CLEAR_AUTH:
      return {} as IAuthState;
    default:
      return state;
  }
}

export default reducer;