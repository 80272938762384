import React, { useCallback, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction } from 'redux';
import { ThunkDispatch } from "redux-thunk";
import { Route } from "react-router-dom";
import { RootState } from '../store/index'

import { initAuthAndUser, setUserIsAuthed } from "../store/action-creators";
import { goToAuth } from "../api/APIUtils";
import { IAuthState } from "../store/reducers/authReducer"; 
import MojioIdentityCallback from "../components/MojioIdentityCallback";

const AuthLayer: React.FC<Props> = ({ children, ...props }: Props) => {
  const { isAuthed, authToken, authLoaded, error, initAuthAndUser, setUserIsAuthed } = props;
  const initAuthAndUserMemo = useCallback(() => initAuthAndUser(), [initAuthAndUser]);
  const setUserIsAuthedMemo = useCallback((authToken) => setUserIsAuthed(authToken), [setUserIsAuthed]);

  useEffect(() => {
    initAuthAndUserMemo();
  }, [initAuthAndUserMemo])

  useEffect(() => {
    if (authLoaded || error) {
      setUserIsAuthedMemo(authToken);
    }
  }, [authToken, authLoaded, error, setUserIsAuthedMemo])

  useEffect(() => {
    if (isAuthed === false) {
      goToAuth();
    }
  }, [authLoaded, isAuthed, error])

  return (
    <>
      <Route
        path="/force-orders/callback"
        component={MojioIdentityCallback}
      />
      {isAuthed ? (
        <>{children}</>
      ) : (
        <></>
      )}
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>
interface Props extends PropsFromRedux {
  children: React.ReactNode;
}

const mapStateToProps = (state: RootState) => ({ 
    isAuthed: state.user.isAuthed, 
    authToken: state.auth,
    authLoaded: state.auth.loaded,
    error: state.auth.error
  }
);
const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>) => ({
  initAuthAndUser: () => dispatch(initAuthAndUser()),
  setUserIsAuthed: (authToken: IAuthState) => dispatch(setUserIsAuthed(authToken))
});
const connector = connect(mapStateToProps, mapDispatchToProps)


export default connector(AuthLayer);
