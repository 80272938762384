import { IVendorOrderDetailsExt, IVendorOrdersDto, JwtPayloadExt } from "../../common/types";
import { ActionType } from "../action-types/index"
import { TOrderFilter } from "../reducers/ordersReducer";

interface SetAuthStart {
  type: ActionType.SET_AUTH_START,
  payload: {}
}
interface SetAuthSuccess {
  type: ActionType.SET_AUTH_SUCCESS,
  payload: { jwt: JwtPayloadExt, accessToken: string }
}

interface SetAuthFail {
  type: ActionType.SET_AUTH_FAIL,
  payload: { error: string }
}
interface ClearAuthAction {
  type: ActionType.CLEAR_AUTH,
  payload: {}
}
interface SetUserAction {
  type: ActionType.SET_USER,
  payload: { jwt: JwtPayloadExt }
}

interface SetUserAuthState {
  type: ActionType.SET_USER_AUTH,
  payload: { isAuthed: boolean }
}

interface SignOutUser {
  type: ActionType.SIGN_OUT_USER,
  payload: {}
}
interface LoadOrdersStart {
  type: ActionType.LOAD_ORDERS_START,
  payload: {}
}
interface LoadOrdersSuccess {
  type: ActionType.LOAD_ORDERS_SUCCESS,
  payload: { dto: IVendorOrdersDto }
}
interface LoadOrdersFail {
  type: ActionType.LOAD_ORDERS_FAIL,
  payload: { error: string }
}
interface LoadOrderDetailsStart {
  type: ActionType.LOAD_ORDER_DETAILS_START,
  payload: {}
}
interface LoadOrderDetailsSuccess {
  type: ActionType.LOAD_ORDER_DETAILS_SUCCESS,
  payload: { dto: IVendorOrderDetailsExt }
}
interface LoadOrderDetailsFail {
  type: ActionType.LOAD_ORDER_DETAILS_FAIL,
  payload: { error: string }
}
interface ApproveOrdersStart {
  type: ActionType.APPROVE_ORDERS_START,
  payload: { orderIds: string[] }
}
interface ApproveOrdersSuccess {
  type: ActionType.APPROVE_ORDERS_SUCCESS,
  payload: { orderIds: string[] }
}
interface ApproveOrdersFail {
  type: ActionType.APPROVE_ORDERS_FAIL,
  payload: { orderIds: string[], error: string }
}
interface SetActiveOrderFilter {
  type: ActionType.SET_ACTIVE_ORDER_FILTER,
  payload: { orderFilter: TOrderFilter }
}
interface SetActivePage {
  type: ActionType.SET_ACTIVE_PAGE,
  payload: { page: number }
}

export type Action = SetAuthStart | SetAuthSuccess| SetAuthFail | ClearAuthAction | SetUserAction | SetUserAuthState | SignOutUser | 
LoadOrdersSuccess | LoadOrdersStart | LoadOrdersFail | LoadOrderDetailsStart | LoadOrderDetailsSuccess | LoadOrderDetailsFail | 
ApproveOrdersStart | ApproveOrdersSuccess | ApproveOrdersFail | SetActiveOrderFilter | SetActivePage; 


export const setAuthStartAction = (): SetAuthStart => ({
  type: ActionType.SET_AUTH_START,
  payload: {}
});

export const setAuthSuccessAction = (jwt: JwtPayloadExt, accessToken: string): SetAuthSuccess => ({
  type: ActionType.SET_AUTH_SUCCESS,
  payload: { jwt, accessToken }
});

export const setAuthFailAction = (error: string): SetAuthFail => ({
  type: ActionType.SET_AUTH_FAIL,
  payload: { error }
});

export const clearAuthAction = (): ClearAuthAction => ({
  type: ActionType.CLEAR_AUTH,
  payload: {}
});

export const setUserAction = (jwt: JwtPayloadExt): SetUserAction => ({
  type: ActionType.SET_USER,
  payload: { jwt }
});

export const setUserAuthStateAction = (isAuthed: boolean): SetUserAuthState => ({
  type: ActionType.SET_USER_AUTH,
  payload: { isAuthed }
})

export const signOutUserAction = (): SignOutUser => ({
  type: ActionType.SIGN_OUT_USER,
  payload: {}
});

export const loadOrdersStartAction = (): LoadOrdersStart => ({
  type: ActionType.LOAD_ORDERS_START,
  payload: {}
});

export const loadOrdersSuccessAction = (dto: IVendorOrdersDto): LoadOrdersSuccess => ({
  type: ActionType.LOAD_ORDERS_SUCCESS,
  payload: { dto }
});

export const loadOrdersFailAction = (error: string): LoadOrdersFail => ({
  type: ActionType.LOAD_ORDERS_FAIL,
  payload: { error }
});

export const loadOrderDetailsStartAction = (): LoadOrderDetailsStart => ({
  type: ActionType.LOAD_ORDER_DETAILS_START,
  payload: {}
});

export const loadOrderDetailsSuccessAction = (dto: IVendorOrderDetailsExt): LoadOrderDetailsSuccess => ({
  type: ActionType.LOAD_ORDER_DETAILS_SUCCESS,
  payload: { dto }
});

export const loadOrderDetailsFailAction = (error: string): LoadOrderDetailsFail => ({
  type: ActionType.LOAD_ORDER_DETAILS_FAIL,
  payload: { error }
});

export const approveOrdersStartAction = (orderIds: string[]): ApproveOrdersStart => ({
  type: ActionType.APPROVE_ORDERS_START,
  payload: { orderIds }
});

export const approveOrdersSuccessAction = (orderIds: string[]): ApproveOrdersSuccess => ({
  type: ActionType.APPROVE_ORDERS_SUCCESS,
  payload: { orderIds }
});

export const approveOrdersFailAction = (orderIds: string[], error: string): ApproveOrdersFail => ({
  type: ActionType.APPROVE_ORDERS_FAIL,
  payload: { orderIds, error }
});

export const setActiveOrderFilterAction = (orderFilter: TOrderFilter): SetActiveOrderFilter => ({
  type: ActionType.SET_ACTIVE_ORDER_FILTER,
  payload: { orderFilter }
})

export const setActivePageAction = (page: number): SetActivePage => ({
  type: ActionType.SET_ACTIVE_PAGE,
  payload: { page }
})