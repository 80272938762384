import React from 'react';
import {
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { Link, useLocation } from 'react-router-dom';
import { ChevronLeftIcon } from '@chakra-ui/icons';

const MAP_PATH_TO_SCREEN_NAME: { [key: string]: string } = {
  'orders': 'Orders'
} 

const getPathArr = (pathname: string): string[] => {
  pathname = pathname.slice(1);
  const pathArr = pathname.split("/");
  pathArr.map((item => item.toLowerCase));
  return pathArr;
}

const BreadCrumbLabel = ({ pathname }: { pathname: string }): JSX.Element => {
  const pathArr: string[] = getPathArr(pathname);
  const backButton = pathArr.length > 1;
  const backlinkPath = `/${pathArr[0]}`;

  if (backButton) {
    return (
      <Link to={{ 
          pathname: backlinkPath,
          state: { prevPath: pathname }
        }}>
        <HStack>
          <Icon as={ChevronLeftIcon} w={35} h={35}></Icon>
          <Heading>{ MAP_PATH_TO_SCREEN_NAME[pathArr[0]] }</Heading>
        </HStack>
      </Link>
    )
  }

  return (
    <Heading>{ MAP_PATH_TO_SCREEN_NAME[pathArr[0]] }</Heading>
  );
}

const Orders: React.FC<Props>  = ({ children }: Props): JSX.Element => {
  const { pathname }: { pathname: string } = useLocation();

  return (
    <Flex
      w="100%"
      h="100%"
      padding="10px 45px"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Flex
        w="100%"
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="row"
      >
        <Box className="breadcrumb">
          <BreadCrumbLabel pathname={pathname} />
        </Box>
      </Flex>   
      {children}  
    </Flex>
  );
}

interface Props {
  children: React.ReactNode;
}

export default Orders;
