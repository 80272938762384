import { IUser, JwtPayloadExt } from '../../common/types';
import { ActionType } from '../action-types';
import { Action } from "../actions/index"

interface IUserState extends IUser {}

const initialState = {
  isAuthed: undefined
} as IUserState;

export const reducer = (state: IUserState = initialState, action: Action): IUserState => {
  switch (action.type) {
    case ActionType.SET_USER:
      const { jwt }: { jwt: JwtPayloadExt } = action.payload;
      return {
        ...state,
        clientId: jwt.client_id,
        firstName: jwt.given_name,
        lastName: jwt.family_name,
        phone: jwt.phone_number,
        fleetRole: jwt.fleetrole,
        tenant: jwt.tenant,
        email: jwt.email,
        tenantAdmin: jwt.tenantadmin,
        vendorAdmin: jwt.vendoradmin,
      }
    case ActionType.SET_USER_AUTH:
      const { isAuthed }: { isAuthed: boolean } = action.payload;
      return {
        ...state,
        isAuthed
      }
    case ActionType.SIGN_OUT_USER:
      return {
        isAuthed: false
      } as IUserState;
    default:
      return state;
  }
}

export default reducer;