import React from "react";
import { Flex, VStack, Spinner, Text } from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";

const Loading = ({ error }: { error: string }): JSX.Element => {
  return (
    <Flex 
      w="100%"
      h="100%"
      minH="inherit"
      alignItems="center"
      justifyContent="center"
    >
    {error ? (
      <VStack>
        <WarningIcon 
          w={30}
          h={30}
          color="gray.300"
        />
        <Text>Something went wrong.</Text>
        <Text>Try refreshing the page.</Text>
      </VStack>
    ) : (
      <Spinner 
        size="xl"
        thickness='3px'
        speed='0.65s'
        emptyColor='gray.200'
        color='blue.500'
      />
    )}
    </Flex>
  );
}

export default Loading;
