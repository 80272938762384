import { extendTheme } from '@chakra-ui/react';

export const themeProvider = extendTheme({
  colors: {
    primary: "#845ec2",
    secondary: "#845ec2",
    highlight: "#845ec2",
    warning: "#845ec2",
    danger: "#845ec2"
  }
})