import {
  HStack,
  Icon,
  Text
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

interface Props {
  ordersLoaded: boolean;
  totalNumOrders: number; 
  activePage: number;
  ordersPerPage: number;
  prevPage: () => void;
  nextPage: () => void;
  scrollToTop: () => void;
}

const PaginationMenu = (props: Props): JSX.Element => {
  const { ordersLoaded, activePage, ordersPerPage, totalNumOrders, prevPage, nextPage, scrollToTop, } = props;
  const rangeStart = (ordersPerPage * activePage) + 1;
  const rangeEnd = Math.min(totalNumOrders, ordersPerPage * (activePage + 1));
  const pageLabel = (!ordersLoaded || totalNumOrders === 0) ? `` : `${rangeStart}-${rangeEnd} of ${totalNumOrders}`;
  const prevBtnEnabled = rangeStart > 1;
  const nextBtnEnabled = rangeEnd < totalNumOrders;

  return (
    <HStack
      h="38px"
      w="100%"
      backgroundColor="gray.100"
      justifyContent="flex-end"
      className="container"
      borderTop="none"
      paddingX="12px"
    >
      <HStack
        spacing="14px"
        fontSize={14}
      >
        <Text>{pageLabel}</Text>
        <HStack 
          color="gray.600"
          spacing="5px">
          <Icon 
            as={ChevronLeftIcon} 
            w={6} h={6}
            color={prevBtnEnabled ? 'gray.600' : 'gray.400'}
            cursor="pointer"
            onClick={() => { 
              if (prevBtnEnabled) { 
                prevPage();
                scrollToTop();
              }
            }}
          ></Icon>
          <Icon 
            as={ChevronRightIcon} 
            w={6} h={6}
            color={nextBtnEnabled ? 'gray.600' : 'gray.400'}
            cursor="pointer"
            onClick={() => { 
              if (nextBtnEnabled) { 
                nextPage();
                scrollToTop();
              }
            }}          
          ></Icon>
        </HStack>
      </HStack>
    </HStack>
  )
}

export default PaginationMenu;
