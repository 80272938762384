import { Box, HStack, VStack, Flex, Text, Button } from "@chakra-ui/react";
import { connect, ConnectedProps } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { useEffect, useState } from "react";
import { AnyAction } from 'redux';

import { IVendorOrderDetailsExt, TVendorOrderStatus } from "../common/types";
import { RootState } from "../store";
import { approveOrder, loadOrderDetails } from "../store/action-creators";
import { formatDateShort } from "../common/utils";
import { ORDER_STATUS_LABELS } from "./Orders";
import Loading from "./Loading";

type TTabView = 'GeneralInfo' | 'OrderFulfillment' | 'InternalNotes';

const OrderDetails = (props: Props) => {
  const { orderId, orders, loading, error, loadOrderDetails, approveOrder } = props;

  const orderDetails: IVendorOrderDetailsExt = orders[orderId] as IVendorOrderDetailsExt;
  const orderIsPatching: boolean = orderDetails?.isPatching;
  const [activeTab, setActiveTab] = useState<TTabView>("GeneralInfo");

  const orderStatusColor = (): string => {
    const orderStatus = Object.values(ORDER_STATUS_LABELS).find(({ status }) =>
     (status.includes(orderDetails?.status as TVendorOrderStatus))
    );
    let color = orderStatus?.colorScheme ? orderStatus.colorScheme : '';
    return color;
  }

  useEffect(() => {
    loadOrderDetails(orderId);
  }, [loadOrderDetails, orderId])

  return (
    <Flex
      w="100%"
      h="100%"
      justifyContent="space-between"
      alignItems="flex-start"
      flexDirection="row"
      gap="35px"
    >
    <Box 
      minH="345px"
      maxW="1060px"
      flex="1 0 auto" 
      className="container"
    >
    {loading || error ? (
      <Loading error={error} />
        ) : (
      <>
        <VStack
          fontSize={14}
          textAlign="left" 
        >
          <HStack
            w="100%"
            h="105px"
            paddingY='20px'
            paddingX='30px'
            alignItems="center"
            flexWrap="wrap"
            spacing="10px"
          >
            <Flex className='info-stack' flex="2 1 auto" >
              <h5>MOJIO ORDER NUMBER</h5>
              <Text fontWeight='700' color='black'>{orderId}</Text>
            </Flex>
            <Flex className='info-stack' flex="2 1 auto" >
              <h5>ORGANIZATION ID</h5>
              <Text>{orderDetails?.organizationId}</Text>
            </Flex>
            <Flex className='info-stack' flex="1 1 auto" >
              <h5>ORDER ORIGIN</h5>
              <Text>{orderDetails?.vendorName}</Text>
            </Flex>
          </HStack>
          <HStack 
            w="100%" 
            paddingX="30px"
            borderTop="2px solid"
            borderBottom="2px solid"
            borderColor="gray.300"
            bg="gray.100" 
            gap="16px"
          >
            <Box
              className={`tab-btn ${activeTab === "GeneralInfo" ? 'active' : ''}`}
              onClick={() => setActiveTab("GeneralInfo" as TTabView)}
            >
              General Information
            </Box>
            {/* <Box
              className={`tab-btn ${activeTab === "OrderFulfillment" ? 'active' : ''}`}
              onClick={() => setActiveTab("OrderFulfillment" as TTabView)}
            >
              Order Fulfillment
            </Box>
            <Box
              className={`tab-btn ${activeTab === "InternalNotes" ? 'active' : ''}`}
              onClick={() => setActiveTab("InternalNotes" as TTabView)}
            >
              Internal Notes
            </Box> */}
          </HStack>
          <HStack 
            w="100%"
            paddingX="30px"
            paddingY="20px"
            alignItems="flex-start" 
            flexWrap="wrap"
            gap="25px"
          >
            <Flex className='info-stack' flex="1 1 220px" >
              <h5>CUSTOMER DETAILS</h5>
              <Text><b>Company:</b> {orderDetails?.companyName}</Text>
              <Text><b>Email:</b>  {orderDetails?.email}</Text>
              <Text><b>Phone:</b>  {orderDetails?.phone}</Text>
            </Flex>
            <Flex className='info-stack' flex="1 1 220px" >
                <h5>BILLING ADDRESS</h5>
              {orderDetails?.billingAddress ? (
              <>
                <Text>{orderDetails?.billingAddress?.address1}</Text>
                <Text>
                  {orderDetails?.billingAddress?.city}
                  {orderDetails?.billingAddress?.region}
                  {orderDetails?.billingAddress?.postalCode}
                </Text>
              </>
              ) : (
                <>--</>
              )}
            </Flex>
            <Flex className='info-stack' flex="1 1 220px" >
              <h5>SHIPPING ADDRESS</h5>
              {orderDetails?.shippingAddress ? (
              <>
                <Text>{orderDetails?.shippingAddress?.address1}</Text>
                <Text>
                  {orderDetails?.shippingAddress?.city}
                  {orderDetails?.shippingAddress?.region}
                  {orderDetails?.shippingAddress?.postalCode}
                </Text>
              </>
              ) : (
                <>--</>
              )}
            </Flex>
            <Flex className='info-stack' flex="1 1 220px" >
              <h5>ORDER DETAILS</h5>
              <Text><b>Ordered:</b> {formatDateShort(orderDetails?.orderDate)}</Text>
              <Text><b>Vendor Id:</b> {orderDetails?.vendorId}</Text>
              <Text><b>Vendor Name:</b> {orderDetails?.vendorName}</Text>
              <Text><b>Quantity:</b> {orderDetails?.quantity}</Text>
            </Flex>
          </HStack>
        </VStack>
      </>
    )}
    </Box>
    <VStack 
      flex="0 0 270px"
      minH="120px"
      padding="20px 30px"
      justifyContent="space-between"
      alignItems="center"
      className="container"
      spacing="25px"
    >
      <HStack w="100%" justifyContent="space-between">
        <Text fontSize="18px">Order Status</Text>
        <Box
            fontSize="14px"
            fontWeight="500"
            padding="1px 6px"
            bg={orderStatusColor()}
            rounded="3px"
            textColor="white"
            textTransform="uppercase"
          >
            {orderDetails?.status}          
        </Box>
      </HStack>
      {orderDetails?.status === "New" && 
        <>
          <Box w="100%" borderBottom="1px solid" borderColor="gray.300" />
          <Button
            isLoading={orderIsPatching}
            loadingText="Approving"
            onClick={() => approveOrder(orderId)}
            fontSize="14px"
            padding="8px 20px"
            fontWeight="500"
            width="100%"
            bg="blue.500"
            rounded="4px"
            textColor="white"
            _hover={{ bg: 'blue.300' }}
          >
            Approve Order  
          </Button>
        </>
      }
    </VStack>
    </Flex>
  );
}

type PropsFromRedux = ConnectedProps<typeof connector>
interface Props extends PropsFromRedux {
  orderId: string
}

const mapStateToProps = (state: RootState) => ({ 
  orders: state.orders.entities,
  loading: state.orders.loadingDetails,
  loaded: state.orders.loadedDetails,
  error: state.orders.error,
});
const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>) => ({
  loadOrderDetails: (orderId: string) => dispatch(loadOrderDetails(orderId)),
  approveOrder: (orderId: string) => dispatch(approveOrder(orderId)),
});
const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(OrderDetails);