export const generateAlphanumeric = (length: number, randomString=""): string => {
  randomString += Math.random().toString(20).substr(2, length);
  if (randomString.length > length) return randomString.slice(0, length);
  return generateAlphanumeric(length, randomString);
};

export const formatDateShort = (date: Date): string => {
  if (!date) return "";
  const dateString = new Date(date).toDateString();
  const dateStringArr = dateString.split(' ');
  dateStringArr.shift();
  dateStringArr[1] += ',';
  return dateStringArr.join(' ');
}