export enum ActionType {
  SET_AUTH_START = "SET_AUTH_START",
  SET_AUTH_SUCCESS = "SET_AUTH_SUCCESS",
  SET_AUTH_FAIL = "SET_AUTH_FAIL",
  CLEAR_AUTH = "CLEAR_AUTH",
  SET_USER = "SET_USER",
  SET_USER_AUTH = "SET_USER_AUTH",
  SIGN_OUT_USER = "SIGN_OUT_USER",
  LOAD_ORDERS_START = "LOAD_ORDERS_START",
  LOAD_ORDERS_SUCCESS = "LOAD_ORDERS_SUCCESS",
  LOAD_ORDERS_FAIL = "LOAD_ORDERS_FAIL",
  LOAD_ORDER_DETAILS_START = "LOAD_ORDER_DETAILS_START",
  LOAD_ORDER_DETAILS_SUCCESS = "LOAD_ORDER_DETAILS_SUCCESS",
  LOAD_ORDER_DETAILS_FAIL = "LOAD_ORDER_DETAILS_FAIL",
  APPROVE_ORDERS_START = "APPROVE_ORDERS_START",
  APPROVE_ORDERS_SUCCESS = "APPROVE_ORDERS_SUCCESS",
  APPROVE_ORDERS_FAIL = "APPROVE_ORDERS_FAIL",
  SET_ACTIVE_ORDER_FILTER = "SET_ACTIVE_ORDER_FILTER",
  SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE",
}