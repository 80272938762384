import { useLocation } from "react-router-dom";

import { TVendorOrderStatus } from "../common/types";
import { TOrderFilter } from "../store/reducers/ordersReducer";
import OrderDetails from "./OrderDetails";
import OrdersPage from "./OrdersList";

type TOrderStatusLabelMap = {
  [key in TOrderFilter]: {
    label: string;
    status: TVendorOrderStatus[];
    colorScheme: string;
  }
}

export const ORDER_STATUS_LABELS: TOrderStatusLabelMap = {
  InQueue: { label: 'IN QUEUE', status: ['New'], colorScheme: 'purple.500' },
  Approved: { label: 'APPROVED', status: ['Approved'], colorScheme: 'blue.500' },
  Shipped: { label: 'SHIPPED', status: ['Shipped'], colorScheme: 'green.500' },
}

const parseOrderId = (pathname: string): string => {
  if (!pathname) return "";
  const url = pathname.slice(1);
  const orderId = url.split("/")[1];
  return orderId;
}  

const Orders = (): JSX.Element => {
  const location = useLocation();
  const orderId = parseOrderId(location?.pathname);

  return (
    <>
      {orderId ? (
        <OrderDetails orderId={orderId}/>
      ) : (
        <OrdersPage/>
      )}
    </>
  );
}

export default Orders;
