import { combineReducers } from "redux";
import userReducer from './userReducer';
import authReducer from './authReducer';
import ordersReducer from './ordersReducer';

const reducers = combineReducers({
  user: userReducer,
  auth: authReducer,
  orders: ordersReducer,
})

export default reducers;

export type RootState = ReturnType<typeof reducers>;