import { connect, ConnectedProps } from "react-redux";
import { NavLink } from "react-router-dom";
import { Text, HStack, ListItem, UnorderedList, Menu, MenuButton, Button, MenuList, MenuItem, Image } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

import { RootState } from "../store";
import { AnyAction } from 'redux';
import { signOutUser } from "../store/action-creators";
import { ThunkDispatch } from "redux-thunk";
import { goToAuth } from "../api/APIUtils";

const NavBar = (props: Props): JSX.Element => {
  const { user, signOutUser } = props;

  const getProfileName = () => {
    const firstName = user.firstName || "";
    const lastName = user.lastName || "";
    if (!firstName && !lastName) return "---";
    return `${user.firstName} ${user.lastName}`
  }

  return (
    <HStack as='header'
      justifyContent="space-between"
      alignItems="center"
      paddingY="10px"
      paddingX="45px"
      className="container"
    >
      <HStack>
        <Image src="/mojio.png" alt="mojio-logo" width="36px" marginRight="5px"></Image>
        <Text fontSize="16px" fontWeight="700" paddingRight="20px">Force Orders</Text>
        <UnorderedList className="navbar">
          <ListItem
            _hover={{ color: 'blue.500' }}
            _activeLink={{ color: 'blue.500' }}
          >
            <NavLink to="/orders">Orders</NavLink>
          </ListItem>
        </UnorderedList>
      </HStack>
      <Menu>
        <MenuButton 
          as={Button} 
          rightIcon={<ChevronDownIcon />}
          background="none"
          _focus={{ boxShadow: "none" }}
          border="0"
        >
         {getProfileName()}
        </MenuButton>
        <MenuList>
          <MenuItem onClick={goToAuth}>Login</MenuItem>
          <MenuItem onClick={signOutUser}>Logout</MenuItem>
        </MenuList>
      </Menu>
    </HStack>
  );
}

type PropsFromRedux = ConnectedProps<typeof connector>
interface Props extends PropsFromRedux {}

const mapStateToProps = (state: RootState) => ({ 
    user: state.user, 
  }
);
const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>) => ({
    signOutUser: () => dispatch(signOutUser())
});
const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(NavBar);
