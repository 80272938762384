import { ILoadingState, TVendorOrder, IVendorOrder, IVendorOrderDetailsExt, TVendorOrderStatus } from '../../common/types';
import { ActionType } from '../action-types';
import { Action } from "../actions/index"

export type TOrderFilter = 'InQueue' | 'Approved' | 'Shipped';
export interface IVendorOrderState extends ILoadingState {
  entities: IVendorOrderEntities;
  activeState: {
    orderFilter: TOrderFilter;
    page: number;
  },
  results: number;
  loadingDetails: boolean;
  loadedDetails: boolean;
}
export interface IVendorOrderEntities {
  [key: string]: TVendorOrder;
}

const initialState = {
  entities: {},
  results: 0,
  activeState: {
    orderFilter: 'InQueue',
    page: 0,
  },
  loading: false,
  loaded: false,
  loadingDetails: false,
  loadedDetails: false,
  error: "",
} as IVendorOrderState;

export const reducer = (state: IVendorOrderState = initialState, action: Action): IVendorOrderState => {
  let orderEntities: IVendorOrderEntities;
  let orderIds: string[];

  switch (action.type) {
    case ActionType.LOAD_ORDERS_START:
      return {
        ...state,
        entities: {},
        loading: true,
        loaded: false,
        error: ""
      }
    case ActionType.LOAD_ORDERS_SUCCESS:
      const { data: orders, results }: { data: IVendorOrder[], results: number } = action.payload.dto;
      orderEntities = {} as IVendorOrderEntities;

      orders.forEach(order => orderEntities[order.orderId] = order);
      return {
        ...state,
        entities: { ...orderEntities },
        results,
        loading: false,
        loaded: true,
      }
    case ActionType.LOAD_ORDERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case ActionType.LOAD_ORDER_DETAILS_START:
      return {
        ...state,
        loadingDetails: true,
        loadedDetails: false,
        error: ""
      }
    case ActionType.LOAD_ORDER_DETAILS_SUCCESS:
      const { dto: orderDetails }: { dto: IVendorOrderDetailsExt } = action.payload;
      orderEntities = { ...state.entities };
      orderEntities[orderDetails?.orderId] = orderDetails;

      return {
        ...state,
        entities: { ...orderEntities },
        loadingDetails: false,
        loadedDetails: true,
      }
    case ActionType.LOAD_ORDER_DETAILS_FAIL:
      return {
        ...state,
        loadingDetails: false,
        error: action.payload.error
      }
    case ActionType.APPROVE_ORDERS_START:
      orderIds = action.payload.orderIds;
      orderEntities = { ...state.entities };
      orderIds.forEach(orderId => orderEntities[orderId].isPatching = true);
      return {
        ...state,
        entities: { ...orderEntities },
      }
    case ActionType.APPROVE_ORDERS_SUCCESS:
      orderIds = action.payload.orderIds;
      orderEntities = { ...state.entities };
      orderIds.forEach(orderId => { 
        orderEntities[orderId].status = 'Approved' as TVendorOrderStatus;
        orderEntities[orderId].isPatching = false;
      });

      return {
        ...state,
        entities: { ...orderEntities },
      }
    case ActionType.APPROVE_ORDERS_FAIL:
      orderIds = action.payload.orderIds;
      orderEntities = { ...state.entities };
      orderIds.forEach(orderId => orderEntities[orderId].isPatching = false);

      return {
        ...state,
        entities: { ...orderEntities },
        error: action.payload.error
      }
    case ActionType.SET_ACTIVE_ORDER_FILTER:
      const { orderFilter }: { orderFilter: TOrderFilter } = action.payload;
      return {
        ...state,
        activeState:  {
          ...state.activeState,
          orderFilter
        }
      }
    case ActionType.SET_ACTIVE_PAGE:
      const { page }: { page: number } = action.payload;
      return {
        ...state,
        activeState:  {
          ...state.activeState,
          page
        }
      }
    default:
      return state;
  }
}

export default reducer;