import { useLocation, Redirect } from "react-router-dom";

import { ITokenCallback } from '../common/types';
import { StoreToken } from "../api/TokenStorage";

const ForceOrdersCallback = (): JSX.Element => {
  try {
    const location = useLocation();
    // parsing the access token and other data returned
    const hash = location.hash.substring(1);
    const tokenArr = hash.split("&");
    const tokenDto: ITokenCallback = convertTokenArrayToObject(tokenArr);
    const accessTokenCode = tokenDto.access_token;
    StoreToken(accessTokenCode);
  } catch(err) {
    console.log('ForceOrdersCallback: Failed to parse callback for token');
  } finally {
    return <Redirect to="/orders" />;
  }
}

const convertTokenArrayToObject = (tokenArr: string[]): ITokenCallback => {
  let tokenObj = {} as ITokenCallback;
  tokenArr.forEach((entry) => {
    const [key, val]: string[] = entry.split("=");
    tokenObj[key] = val;
  })
  return tokenObj;
}

export default ForceOrdersCallback;